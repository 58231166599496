<template>
  <!-- 数据看板>经营看板 -->
  <div class="dict">
    <div class="boardTitle">经营指标看板</div>
    <ul class="completionRate">
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/income.png" alt="" />
        </div>
        <div class="rightDetail">
          <div class="rate">{{ incomeRate }}%</div>
          <div class="rateTitle">收入执行率 
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计（9月1日至今）<br/>
                收入执行率=收入结算额/目标额
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/profit.png" alt="" />
        </div>
        <div class="rightDetail">
          <div class="rate">{{ profitRate }}%</div>
          <div class="rateTitle">利润执行率 
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计（9月1日至今）<br/>
                利润执行率=实际利润/目标利润
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/admissions.png" alt="" />
        </div>
        <div class="rightDetail">
          <div class="rate">{{ enrollRate }}%</div>
          <div class="rateTitle">招生完成率
            <el-tooltip placement="top">
              <div slot="content">
                本学期累计（上学期3.15开始、下学期9.15开始）<br />
                招生完成率=本期实际招生人数（定金+全费）/招生目标；<br />
                招生目标=在校生目标-（在校人数-预计流失-预计毕业）；接口返回的在校人数已将预计流失人数、预计毕业人数去掉；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/school.png" alt="" />
        </div>
        <div class="rightDetail">
          <div class="rate">{{ schoolExpandRate }}%</div>
          <div class="rateTitle">园校拓展
            <el-tooltip placement="top">
              <div slot="content">
                最新更新数据<br/>
                园校拓展进度=已签约总数/园校拓展目标总数
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="tateTotal">支出预算执行率
          <el-tooltip placement="top">
            <div slot="content">
              本学年累计（9月1日至今）<br/>
              总支出预算执行率=总支出金额/总支出目标额<br/>
              伙食费支出预算执行率=伙食费支出金额/伙食费支出目标额<br/>
              人力人本支出预算执行率=人力成本支出金额/人力成本支出目标额
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </div>
        <ul>
          <li>
            <span class="colorDot"></span>
            总支出预算执行率
            <span>{{ expendRate.total }}%</span>
          </li>
          <li>
            <span class="colorDot"></span>
            伙食费支出预算执行比
            <span>{{ expendRate.wages }}%</span>
          </li>
          <li>
            <span class="colorDot"></span>
            人力成本支付预算执行比
            <span>{{ expendRate.human }}%</span>
          </li>
        </ul>
      </li>
    </ul>
    <div class="echrtsBox">
      <div class="echarts">
        <!-- <p class="echartsTitle">伙食费执行率</p> -->
        <div class="echartsType">
          <p class="echartsTitle">伙食费执行率
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计（9月1日至今）<br/>
                伙食费支出实际执行率=伙食费支出金额/伙食费收入结算金额<br/>
                伙食费支出预算执行率=伙食费支出金额/伙食费预算金额
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <el-radio-group
            v-model="wagesExecute"
            @change="changeWagesExecute"
            :loading="remindLoading"
          >
            <el-radio-button label="1">实际支出</el-radio-button>
            <el-radio-button label="2">预算支出</el-radio-button>
          </el-radio-group>
        </div>
        <div class="chartContent" v-show="Object.keys(echartData1).length">
          <div id="echarts1"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData1).length">
          暂无数据
        </div>
      </div>
      <!-- <div class="echarts">
        <p class="echartsTitle">收入分布</p>
        <div class="chartContent" v-show="Object.keys(echartData2).length">
          <div id="echarts2"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData2).length">
          暂无数据
        </div>
      </div> -->
      <div class="echarts">
        <div class="echartsType">
          <p class="echartsTitle">生均利润
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计（9月1日至今）<br/>
                生均利润=总收入/总人数
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </p>
          <el-radio-group v-model="profit" @change="changeProfit">
            <el-radio-button label="1">前五名</el-radio-button>
            <el-radio-button label="2">后五名</el-radio-button>
          </el-radio-group>
        </div>
        <div class="chartContent" v-show="Object.keys(echartData3).length">
          <div id="echarts3"></div>
        </div>
        <div class="noEachartData" v-if="!Object.keys(echartData3).length">
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extension } from "@/utils/validate";
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
// import {EleResize} from '@/assets/css/resize.js'

export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      profit: 1,
      enrollRate: 0,
      expendRate: {
        human: 0,
        total: 0,
        wages: 0,
      },
      incomeRate: 0,
      profitRate: 0,
      wagesExecute: 1,
      schoolExpandRate: 0,
      echartData1: {
        name: [],
      },
      echartData2: {
        name: [],
      },
      echartData3: {
        name: [],
      },
      fullscreenLoading: false,
      remindLoading: false,
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getResize();
  },
  created() {
    this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
    setTimeout(() => {
      this.initEcharts1(1);
      this.initEchartsTwo();
      // this.initEcharts2();
      this.initEcharts3(1);
    }, 100);
    this.getSchoolExpand();
    window.addEventListener("resize", () => {
      let echarts1 = this.$echarts.init(document.getElementById("echarts1"));
      // let echarts2 = this.$echarts.init(document.getElementById("echarts2"));
      let echarts3 = this.$echarts.init(document.getElementById("echarts3"));
      echarts1.resize();
      // echarts2.resize();
      echarts3.resize();
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      // 招生完成率
      this.$api
        .getEnrollRate({
          menu: 1,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.enrollRate = res.data.data.rate;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      // 支出预算执行率
      this.$api
        .getExpendRate({
          type: 1,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.expendRate = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      // 收入执行率
      this.$api
        .getIncomeRate({
          type: 1,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.incomeRate = res.data.data.rate;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      // 利润执行率
      this.$api.getProfitRate().then((res) => {
        if (res.data.code == "0") {
          this.profitRate = res.data.data.rate;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    changeWagesExecute(val) {
      this.remindLoading = true;
      setTimeout(() => {
        this.remindLoading = false;
      }, 2000);
  
      // this.initEcharts1(val);

      if(val==1 && this.echartData1){
        this.myEchartLv(this.echartData1)
      }else if(val==2 && this.echartDataTwo){
          this.myEchartLv(this.echartDataTwo) 
      }
    
    },

    initEcharts1() {
      // this.openFullScreen2();
      this.$api
        .getWagesExecute({
          menu: 1,
          type: 1,
        })
        .then((res) => {
          if (res.data.code == "0") {
            // this.profitRate = res.data.data.rate
            let data = res.data.data;
            this.echartData1 = data;
            this.myEchartLv(data)
            // window.onresize(function () {
            // });
            // echarts1.resize();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    initEchartsTwo() {
      // this.openFullScreen2();
      this.$api
        .getWagesExecute({
          menu: 1,
          type: 2,
        })
        .then((res) => {
          if (res.data.code == "0") {
            // this.profitRate = res.data.data.rate
            let data = res.data.data;
            this.echartDataTwo = data;
            console.log(this.echartDataTwo,'twodeideeezhizhizhizh')
            // window.onresize(function () {
            // });
            // echarts1.resize();
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    myEchartLv(data){
      let echarts1 = this.$echarts.init(
        document.getElementById("echarts1")
      );
      // 绘制星期-出险数量对比图图表
      let mealExpenses = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          backgroundColor: "rgba(9, 12, 21, 0.8)",
          borderColor: "rgba(9, 12, 21, 0.8)",
          textStyle: {
            fontSize: 14,
            textAlign: "center",
            color: "#fff",
          },
          formatter: function (val) {
            return (
              val[0].name +
              '<span style="width:20px;display: inline-block;"></span>' +
              val[0].value +
              "%"
            );
          },
        },
        xAxis: {
          type: "category",
          data: data.post,
          triggerEvent: true,
          axisTick: {
            show: false, //不显示坐标轴刻度
          },
          axisLine: {
            show: false, //不显示坐标轴轴线
          },
          axisLabel: {
            rotate: 40,
            textStyle: {
              //改变xy轴上文字的颜色
              color: "#A8A6B9",
            },
            margin: 8,
            formatter:function(params){
              var val="";
              if(params.length >4){
                  val = params.substr(0,4)+'...';
                  return val;
              }else{
                  return params;
              }
            }
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#A8A6B9",
            },
            formatter: function (value) {
              return value + "%";
            },
          },
        },
        grid: {
          bottom: 60,
        },
        series: [
          {
            data: data.userNumber,
            type: "bar",
            barWidth: 6, //柱图宽度
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [2, 2, 0, 0],
                color: function (params) {
                  if (params.data >= 100) {
                    return "#E7C69C";
                  } else if (
                    params.dataIndex ==
                    data.userNumber.length - 1
                  ) {
                    return "#00AB9F";
                  } else {
                    return "#004DA0";
                  }
                },
              },
            },
          },
        ],
      };
      
      echarts1.setOption(mealExpenses);
      extension(echarts1);
    },

    // openFullScreen2() {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: "Loading",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    //   setTimeout(() => {
    //     loading.close();
    //   }, 2000);
    // },

    initEcharts2() {
      this.$api
        .getIncomeDistribute({
          type: 1,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData2 = data;
            let echarts2 = this.$echarts.init(
              document.getElementById("echarts2")
            );
            // 绘制星期-出险数量对比图图表
            let mealExpenses = {
              title: {
                textStyle: {
                  fontSize: 14,
                  fontWeight: "",
                  color: "#333",
                },
              }, //标题
              tooltip: {
                show: false,
              }, //提示框，鼠标悬浮交互时的信息提示
              legend: {
                show: false,
                orient: "vertical",
                x: "left",
                data: data.post,
              }, //图例属性，以饼状图为例，用来说明饼状图每个扇区，data与下边series中data相匹配
              graphic: {
                type: "text",
                left: "center",
                top: "center",
              },
              series: [
                {
                  name: "", //tooltip提示框中显示内容
                  type: "pie", //图形类型，如饼状图，柱状图等
                  radius: ["0%", "50%"], //饼图的半径，数组的第一项是内半径，第二项是外半径。支持百分比，本例设置成环形图。具体可以看文档或改变其值试一试
                  //roseType:'area',是否显示成南丁格尔图，默认false
                  roseType: "area",
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        textStyle: {
                          color: "#3c4858",
                          fontSize: "12",
                          textAlgin: "center",
                        },
                        formatter: function (val) {
                          //让series 中的文字进行换行
                          return val.name + "\n" + val.value;
                        },
                      }, //饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等。可以与itemStyle属性同级，具体看文档
                      labelLine: {
                        show: true,
                        lineStyle: { color: "#ececf1" },
                      }, //线条颜色
                    }, //基本样式
                    emphasis: {
                      // shadowBlur: 10,
                      // shadowOffsetX: 0,
                      // shadowColor: 'rgba(0, 0, 0, 0.5)',//鼠标放在区域边框颜色
                      textColor: "#6C7293",
                    }, //鼠标放在各个区域的样式
                  },
                  data: data, //数据，数据中其他属性，查阅文档
                  color: [
                    "#004DA0",
                    "#E7C69C",
                    "#2F5ECB",
                    "#93252A",
                    "#00AB9F",
                  ], //各个区域颜色
                }, //数组中一个{}元素，一个图，以此可以做出环形图
              ], //系列列表
            };
            echarts2.setOption(mealExpenses);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    initEcharts3(type) {
      this.$api
        .getProfit({
          menu: 1,
          type: type,
        })
        .then((res) => {
          if (res.data.code == "0") {
            let data = res.data.data;
            this.echartData3 = data;
            let echarts3 = this.$echarts.init(
              document.getElementById("echarts3")
            );
            // 绘制星期-出险数量对比图图表
            let mealExpenses = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
                backgroundColor: "rgba(9, 12, 21, 0.8)",
                borderColor: "rgba(9, 12, 21, 0.8)",
                textStyle: {
                  fontSize: 14,
                  textAlign: "center",
                  color: "#fff",
                },
                formatter: function (val) {
                  return (
                    val[0].name +
                    '<span style="width:20px;display: inline-block;"></span>' +
                    val[0].value
                  );
                },
              }, //提示框，鼠标悬浮交互时的信息提示
              xAxis: {
                type: "value",
                triggerEvent: true,
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
                axisLabel: {
                  rotate:30,
                  textStyle: {
                    //改变xy轴上文字的颜色
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >3){
                        val = params.substr(0,3)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                  // formatter:function(params){
                  //   return params+'%'
                  // }
                },
              },
              grid: {
                // top: '5% '
                left: 100,
                right: 20,
                bottom: 30,
              },
              yAxis: {
                type: "category",
                data: data.post.reverse(),
                triggerEvent: true,
                axisLabel: {
                  textStyle: {
                    color: "#A8A6B9",
                  },
                  margin: 8,
                  formatter:function(params){
                    var val="";
                    if(params.length >6){
                        val = params.substr(0,6)+'...';
                        return val;
                    }else{
                        return params;
                    }
                  }
                },
                formatter: "{c}",
                axisTick: {
                  show: false, //不显示坐标轴刻度
                },
                axisLine: {
                  show: false, //不显示坐标轴轴线
                },
              },
              series: [
                {
                  // data: ['郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园','郑州中心园'],
                  data: data.userNumber.reverse(),
                  type: "bar",
                  showBackground: true,
                  // bottom:10,
                  barWidth: 20, //柱图宽度
                  itemStyle: {
                    //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                      //柱形图圆角，初始化效果
                      barBorderRadius: [0, 4, 4, 0],
                      color: "#004DA0",
                      label: {
                        formatter: "{c}",
                        show: true,
                        position: "right",
                        textStyle: {
                          fontSize: "14",
                          color: "#9290B1",
                        },
                      },
                    },
                  },
                  backgroundStyle: {
                    color: "#F3F3FB",
                  },
                },
              ],
            };
            echarts3.setOption(mealExpenses);
            extension(echarts3);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    changeProfit(val) {
      this.initEcharts3(val);
    },
    //园校拓展
    getSchoolExpand() {
      this.$api
        .getSchoolExpand({
          type: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // let schoolExpand = res.data.data;
            if (res.data.data) {
              this.schoolExpandRate = res.data.data[0].rate;
            }
            // this.schoolExpandChartInit(schoolExpand);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  
    getResize() {
      // window.onresize = function () {
      //     echarts1.resize()
      // }
      // // this.charts = echarts.init(echarts1) // 图标ID初始化
      // // 初始化element-resize-detector组件
      // let elementResize = elementResizeDetectorMaker({
      //   strategy: 'scroll', // <- 推荐监听滚动，提升性能
      //   callOnAdd: true // 添加侦听器时是否应调用,默认true
      // })
      // elementResize.listenTo(echarts1, function(element) {
      //   echarts.init(echarts1).resize() // 当元素尺寸发生改变是会触发此事件，刷新图表
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #f4f4fc;
  height: calc(100% - 64px);
  min-width: 1200px;
  overflow-x: scroll;
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 62px);
  }
  .boardTitle {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }
  .completionRate {
    margin-top: 20px;
    display: flex;
    li {
      width: 20%;
      background-color: #fff;
      padding: 20px 0 20px 20px;
      padding-left: 20px;
      border-radius: 8px;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      display: flex;
      align-items: center;
      & + li {
        margin-left: 16px;
      }
      &:last-child {
        padding: 0;
        display: flex;
        flex-direction: column;
        padding: 14px 0 14px 14px;
        align-items: flex-start;
        .tateTotal {
          color: #3d3e4f;
          font-size: 18px;
          text-align: left;
        }
        ul {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          justify-content: flex-start;
          font-size: 14px;
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            width: 100%;
            margin-left: 0;
            font-size: 14px;
            line-height: 20px;
            color: #a7a5b9;
            .colorDot {
              width: 10px;
              height: 10px;
              background: #c6000b;
              border-radius: 50%;
              margin-right: 5px;
              margin-left: 0;
            }
            &:last-child {
              display: flex;
              flex-direction: row;
            }
            &:first-child .colorDot {
              background: #c6000b;
            }
            &:nth-child(2) .colorDot {
              background: #004da0;
            }
            &:nth-child(3) .colorDot {
              background: #e7c69c;
            }
            span {
              color: #231f32;
              margin-left: 5px;
            }
          }
        }
      }
      .leftIcon {
        width: 62px;
        height: 62px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .leftIcon:first-child {
        background: #93252a;
      }
      &:nth-child(2) .leftIcon {
        background: #00ab9f;
      }
      &:nth-child(3) .leftIcon {
        background: #004da0;
      }
      &:nth-child(4) .leftIcon {
        background: #e7c69c;
      }
      .rate {
        font-size: 22px;
        color: #231f32;
        line-height: 30px;
      }
      .rateTitle {
        color: #a7a5b9;
        font-size: 16px;
      }
      .rightDetail {
        margin-left: 14px;
      }
    }
  }
  .echrtsBox {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .echarts {
      height: 380px;
      width: 49.2%;
      background-color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      .echartsType {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        .echartsTitle {
          padding: 0;
        }
      }
      .chartContent {
        width: calc(100% - 20px);
        height: calc(100% - 70px);
        padding: 10px;
      }
      #echarts1,
      #echarts2,
      #echarts3 {
        width: 100%;
        height: 100%;
      }
    }
    // .echarts{
    //   width: 50%;
    // }
    // .echarts:first-child {
    //   width: 46%;
    //   margin-right: 30px;
    // }
    // .echarts:nth-child(2) {
    //   width: 46%;
    // }
    // .echarts:nth-child(2) {
    //   width: 28%;
    //   margin-right: 30px;
    // }
    // .echarts:nth-child(3) {
    //   width: 30%;
    // }
    .echartsTitle {
      padding: 28px 0;
      font-size: 18px;
      color: #3d3e4f;
    }
  }
}
/deep/.el-radio-button__inner {
  padding: 8px;
}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .dict {
    height: calc(100% - 64px);
    padding: 34px 30px 30px 30px;
    .completionRate {
      margin-top: 20px;
      li {
        width: 20%;
        background-color: #fff;
        padding: 40px 0 40px 40px;
        padding-left: 40px;
        &:last-child {
          padding: 18px 0 18px 18px;
          .tateTotal {
            font-size: 18px;
          }
          ul {
            li {
              font-size: 14px;
              line-height: 30px;
            }
          }
        }
        .leftIcon {
          width: 92px;
          height: 92px;
        }
        .rate {
          font-size: 30px;
          line-height: 40px;
        }
        .rateTitle {
          font-size: 20px;
        }
        .rightDetail {
          margin-left: 22px;
        }
      }
    }
    .rateTitle {
      font-size: 20px;
    }
    .rightDetail {
      margin-left: 22px;
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1600px) {
  .dict {
    // padding: 20px;
    .completionRate {
      margin-top: 10px;
      li {
        width: 20%;
        background-color: #fff;
        padding: 20px 0 20px 20px;
        padding-left: 20px;
        &:last-child {
          padding: 16px 0 16px 16px;
          .tateTotal {
            font-size: 16px;
          }
          ul {
            li {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
        .leftIcon {
          width: 52px;
          height: 52px;
          img {
            width: 26px;
          }
        }
        .rate {
          font-size: 20px;
          line-height: 30px;
        }
        .rateTitle {
          font-size: 16px;
        }
        .rightDetail {
          margin-left: 14px;
        }
      }
    }
    .rateTitle {
      font-size: 18px;
    }
    .rightDetail {
      margin-left: 18px;
      img{
        width:14px;
      }
    }
  }
}
.noEachartData {
  height: calc(100% - 74px);
  width: 100%;
  // height: 100%;
  // text-align: center;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/div {
    display: none;
  }
}
</style>
